import {RECENT_NEWS_SUCCESS} from "../../actions/types";

const initialState =  { recentNews: null };
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RECENT_NEWS_SUCCESS:
            return {
                ...state,
                recentNews: payload.recentNews,
            };
        default:
            return state;
    }
}
