import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from './stores/store'
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalContext } from "./helpers/hoc";
const globalContextValue = {
    cdnBaseUrl: process.env.REACT_APP_CDN_BASE_URL
};

ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
            <GlobalContext.Provider value={globalContextValue}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </GlobalContext.Provider>
        </GoogleReCaptchaProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
