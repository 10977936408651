import {
    SEARCH_COMPANIES_SUCCESS,
    CLEAR_COMPANIES_SEARCH_RESULT,
    SEARCH_CONTACTS_SUCCESS,
    CLEAR_CONTACTS_SEARCH_RESULT, SEARCH_INSTITUTIONS_SUCCESS, CLEAR_INSTITUTIONS_SEARCH_RESULT
} from "../../actions/types";

const initialState =  { companies: [], institutions: [], contacts: [] };
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: payload.companies,
            };
        case CLEAR_COMPANIES_SEARCH_RESULT:
            return {
                ...state,
                companies: []
            };
        case SEARCH_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: payload.contacts,
            };
        case CLEAR_CONTACTS_SEARCH_RESULT:
            return {
                ...state,
                contacts: []
            };
        case SEARCH_INSTITUTIONS_SUCCESS:
            return {
                ...state,
                institutions: payload.institutions,
            };
        case CLEAR_INSTITUTIONS_SEARCH_RESULT:
            return {
                ...state,
                institutions: []
            };
        default:
            return state;
    }
}