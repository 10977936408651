import './styles/output.css'
import React, {Component} from "react";
import ReactGA from "react-ga4";

import {
    Routes,
    Route
} from "react-router-dom";

// import your route components too
import Index from './views/Index';
import Login from './views/Login';
import Signup from './views/Signup';
import NotFound from './views/NotFound';
import Privacy from './views/Privacy';
import ResetPassword from "./views/ResetPassword";
import PasswordChanged from "./views/PasswordChanged";
import {getCurrentUser} from "./actions/auth/auth";
import {connect} from "react-redux";
import Company from "./views/Company";
import Layout from "./components/Layout/Layout";
import {withLocation} from "./helpers/hoc";
import Companies from "./views/Companies";
import Contacts from "./views/Contacts";
import Institutions from "./views/Institutions";
import Profile from "./views/Profile";

ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS);

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        }
    }

    componentDidMount() {
        const {dispatch, user} = this.props;
        if (!user) {
            dispatch(getCurrentUser()).then(() => {
                this.setState({ isReady: true });
            });
        } else {
            this.setState({ isReady: true });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactGA.send({ hitType: "pageview", page: this.props.location.pathname + this.props.location.search });
    }

    render() {
        const { isReady } = this.state;
        return (
            <>
                {
                    isReady && (
                        <Routes>
                            <Route index element={<Index/>}/>
                            <Route path="login" element={<Login/>}/>
                            <Route path="signup" element={<Signup/>}/>
                            <Route path="company" element={<Layout><Company/></Layout>}/>
                            <Route path="notfound" element={<NotFound/>}/>
                            <Route path="companies" element={<Layout><Companies/></Layout>}/>
                            <Route path="profile" element={<Layout><Profile/></Layout>}/>
                            <Route path="new-profile" element={<Layout><Profile/></Layout>}/>
                            <Route path="institutions" element={<Layout access="isAuthenticated"><Institutions/></Layout>}/>
                            <Route path="contacts" element={<Layout access="isAuthenticated"><Contacts/></Layout>}/>
                            <Route path="reset-password" element={<ResetPassword/>}/>
                            <Route path="password-changed" element={<PasswordChanged/>}/>
                            <Route path="privacy" element={<Layout><Privacy/></Layout>}/>
                        </Routes>
                    )
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(withLocation(App));