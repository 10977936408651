import React, {Component} from "react";
import logo from '../assets/logo.png';
import {changePassword, resetPassword} from "../actions/auth/auth";
import {connect} from "react-redux";
import {withNavigation, withSearchParams} from "../helpers/hoc";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import Alert from "../components/Alert/Alert";

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.handleInitiateResetPasswordSubmit = this.handleInitiateResetPasswordSubmit.bind(this);
        this.handleConfirmResetPasswordSubmit = this.handleConfirmResetPasswordSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.state = {
            username: "",
            error: null,
            password: "",
            confirmedPassword: null,
            message: null
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    async handleInitiateResetPasswordSubmit(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
        });
        const {executeRecaptcha} = this.props.googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const token = await executeRecaptcha('resetPassword');
        const {dispatch} = this.props;
        dispatch(resetPassword(this.state.username, token))
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    message: "An email has been sent. Please check your inbox."
                });
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }

     handleConfirmResetPasswordSubmit(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
        });
         const [searchParams] = this.props.searchParams;
         const token = searchParams.get("token");
        const {dispatch} = this.props;
        dispatch(changePassword(this.state.password, token))
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
                this.props.navigate("/password-changed", {})
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }

    onChangePassword(e) {
        const { confirmedPassword } = this.state;
        const isPasswordNotConfirmed = confirmedPassword !== e.target.value;
        this.setState({
            ...this.state,
            password: e.target.value,
            error: isPasswordNotConfirmed ? "Passwords must be confirmed" : null,
            isPasswordConfirmed: !isPasswordNotConfirmed
        });
    }

    onChangeConfirmPassword(e) {
        const { password } = this.state;
        const isPasswordNotConfirmed = password !== e.target.value;
        this.setState({
            ...this.state,
            confirmedPassword: e.target.value,
            error: isPasswordNotConfirmed ? "Passwords must be confirmed" : null,
            isPasswordConfirmed: !isPasswordNotConfirmed
        });
    }

    render() {
        const [searchParams] = this.props.searchParams;
        const token = searchParams.get("token");
        if (token) {
            return (<div className="relative min-h-screen bg-slate-100 overflow-hidden">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                        <a href="/">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={logo}
                                alt="Built In Africa"
                            />
                        </a>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="mt-6 relative">
                                Enter your new password
                            </div>

                            <div className="mt-6">
                                <form className="space-y-6"
                                      onSubmit={this.handleConfirmResetPasswordSubmit}
                                      method="POST"
                                      ref={(c) => {
                                          this.form = c;
                                      }}
                                >
                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={this.onChangePassword}
                                            value={this.state.password}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                            Confirm password
                                        </label>
                                        <input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={this.onChangeConfirmPassword}
                                        />
                                    </div>
                                    <Alert message={this.props.error || this.state.error}/>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Reset password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        } else {
            return (<div className="relative min-h-screen bg-slate-100 overflow-hidden">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                        <a href="/">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={logo}
                                alt="Built In Africa"
                            />
                        </a>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            {
                                this.state.message ?
                                    <div>
                                        <div className="mt-6 relative">
                                            {this.state.message}
                                        </div>
                                        <div className="mt-6 text-center">
                                            <a onClick={() => this.props.navigate("/", {})} className="text-base font-medium text-indigo-600 hover:text-indigo-500">Go
                                                back home
                                            </a>
                                        </div>
                                    </div> :
                                    <div>
                                        <div className="mt-6 relative">
                                            Enter your email and we'll send you a link to get back into your account.
                                        </div>
                                        <div className="mt-6">
                                            <form className="space-y-6"
                                                  onSubmit={this.handleInitiateResetPasswordSubmit}
                                                  method="POST"
                                                  ref={(c) => {
                                                      this.form = c;
                                                  }}
                                            >
                                                <div>
                                                    <input
                                                        type="email"
                                                        name="username"
                                                        id="username"
                                                        autoComplete="email"
                                                        placeholder="Email"
                                                        required
                                                        value={this.state.username}
                                                        onChange={this.onChangeUsername}
                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <Alert message={this.props.error || this.state.error}/>
                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        Reset password
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>);
        }
    }
}

function mapStateToProps(state) {
    const {email, token, error} = state.auth;
    return {
        email,
        token,
        error
    };
}

export default connect(mapStateToProps)(withNavigation(withSearchParams(withGoogleReCaptcha(ResetPassword))));
