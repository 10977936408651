import React, { Component } from "react";
import { Popover } from "@headlessui/react";
import logo from '../../assets/logo.png';
import {connect} from "react-redux";
import { withNavigation } from "../../helpers/hoc";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { user } = this.props;
        let rightMenu;
        if (user) {
            rightMenu = <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                { user.imageUrl ?
                    <img className="h-10 w-10 rounded-full" src={user.avatar} alt="" /> :
                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                }
            </div>
        } else {
            rightMenu = <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <button onClick={() => { this.props.navigate("/login", {});}}
                        className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    Sign in
                </button>
                <button
                    onClick={() => { this.props.navigate("/signup", {}); }}
                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-purple-700 hover:to-indigo-700"
                >
                    Sign up
                </button>
            </div>
        }
        return (
            <div>
                <header>
                    <Popover className="relative bg-white">
                        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                <a href="/">
                                    <span className="sr-only">Built In Africa</span>
                                    <img
                                        className="h-8 w-auto sm:h-16"
                                        src={logo}
                                        alt=""
                                    />
                                </a>
                            </div>
                            { rightMenu }
                        </div>
                    </Popover>
                </header>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(withNavigation(Header));
