export const API_CALL_ERROR = "API_CALL_ERROR";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const CLEAR_COMPANIES_SEARCH_RESULT = "CLEAR_COMPANIES_SEARCH_RESULT";
export const CLEAR_CONTACTS_SEARCH_RESULT = "CLEAR_CONTACTS_SEARCH_RESULT";
export const CLEAR_INSTITUTIONS_SEARCH_RESULT = "CLEAR_INSTITUTIONS_SEARCH_RESULT";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const MAIN_STATS_SUCCESS = "MAIN_STATS_SUCCESS";
export const EXTENDED_STATS_SUCCESS = "EXTENDED_STATS_SUCCESS";
export const SEARCH_COMPANIES_SUCCESS = "SEARCH_COMPANIES_SUCCESS";
export const SEARCH_CONTACTS_SUCCESS = "SEARCH_CONTACTS_SUCCESS";
export const SEARCH_INSTITUTIONS_SUCCESS = "SEARCH_INSTITUTIONS_SUCCESS";
export const RECENT_NEWS_SUCCESS = "RECENT_NEWS_SUCCESS";
