import React, {Component} from "react";
import {getRecentNews} from "../../actions/news/news";
import {connect} from "react-redux";
import {getFormattedDate, getNewsSnippet, getNewsTitle} from "../../helpers/formatters";


class News extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {dispatch} = this.props;
        if (null === this.props.recentNews) {
            dispatch(getRecentNews());
        }
    }

    render() {
        return (
            <div className="relative bg-gray-50 pt-6 pb-20 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3"/>
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">From the
                            news</h2>
                        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                            Discover the latest news about the aspiring founders building the next generation of
                            African startups.
                        </p>
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {this.props?.recentNews?.map((news) => (
                            <div key={news.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={news.mainImage} alt=""/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            Article
                                        </p>
                                        <a href={news.link} className="block mt-2" target="_blank" rel="nofollow">
                                            <p className="text-xl font-semibold text-gray-900">{getNewsTitle(news.title)}</p>
                                            <p className="mt-3 text-base text-gray-500">{getNewsSnippet(news.snippet)}</p>
                                        </a>
                                    </div>
                                    <div className="mt-6 flex items-center">
                                        <div className="ml-3">
                                            <div className="flex space-x-1 text-sm text-gray-500">
                                                <span>Published on </span>
                                                <span aria-hidden="true">·</span>
                                                <time dateTime={getFormattedDate(news.datePublishedTimestamp)}>{getFormattedDate(news.datePublishedTimestamp)}</time>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {recentNews} = state.news;
    return {
        recentNews
    };
}

export default connect(mapStateToProps)(News);
