import React, {Component} from "react";
import {connect} from "react-redux";
import {getExtendedStats} from "../../actions/stats/stats";

class ExtendedStats extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        if (null === this.props.extendedStats) {
            dispatch(getExtendedStats());
        }
    }

    render () {
        return (
            <div className="relative bg-gray-900">
                <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                    <div className="h-full w-full xl:grid xl:grid-cols-2">
                        <div className="h-full xl:relative xl:col-start-2">
                            <img
                                className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                                src={"/media/website/main-background.jpg"}
                                alt="People working on laptops"
                            />
                            <div
                                aria-hidden="true"
                                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                            />
                        </div>
                    </div>
                </div>
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                    <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                        <h2 className="text-sm font-semibold tracking-wide uppercase">
                                <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">
                                  Valuable Metrics
                                </span>
                        </h2>
                        <p className="mt-3 text-3xl font-extrabold text-white">
                            Get comprehensive and actionable data to empower your research
                        </p>
                        <p className="mt-5 text-lg text-gray-300">
                            Our curated and frequently updated datasets provide valuable insights and help you discover trends in the fast growing startup ecosystems in Africa.
                            Whether you are startup looking to raise funds or an investor looking for the next opportunity, <strong className="text-indigo-400">BuiltInAfrica</strong> is the only platform that
                            offers you the data intelligence you need at your fingertips.
                        </p>
                        <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                            <p>
                                <span className="block text-2xl font-bold text-white">{this.props?.extendedStats?.companies}</span>
                                <span className="mt-1 block text-base text-gray-300">
                                <span className="font-medium text-white">Active startups</span></span>
                            </p>
                            <p>
                                <span className="block text-2xl font-bold text-white">{this.props?.extendedStats?.countries}</span>
                                <span className="mt-1 block text-base text-gray-300">
                                <span className="font-medium text-white">Vibrating ecosystems</span></span>
                            </p>
                            <p>
                                <span className="block text-2xl font-bold text-white">{this.props?.extendedStats?.institutions}</span>
                                <span className="mt-1 block text-base text-gray-300">
                                <span className="font-medium text-white">Active investors</span></span>
                            </p>
                            <p>
                                <span className="block text-2xl font-bold text-white">{this.props?.extendedStats?.amountRaised}</span>
                                <span className="mt-1 block text-base text-gray-300">
                                <span className="font-medium text-white">Raised</span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { extendedStats } = state.stats;
    return {
        extendedStats
    };
}

export default connect(mapStateToProps)(ExtendedStats);
