export const getNewsTitle = (title) => {
    if (title.length > 60) {
        return `${title.substring(0,60)}...`;
    }
    return title;
}

export const getNewsSnippet = (snippet) => {
    if (snippet.length > 200) {
        return `${snippet.substring(0,200)}...`;
    }
    return snippet;
}

export const getFormattedDate = (datePublishedTimestamp) => {
    return new Date(datePublishedTimestamp*1000).toDateString();
}

export const getCompanySize = (value) => {
    switch (value) {
        case "FROM_0_TO_10": {
            return "0 - 10 Employees";
        }
        case "FROM_11_TO_50": {
            return "11 - 50 Employees";
        }
        case "FROM_51_TO_100": {
            return "50 - 100 Employees";
        }
        case "FROM_101_TO_500":{
            return "100 - 500 Employees";
        }
        case "FROM_500_TO_999":{
            return "500 - 999 Employees";
        }
        case "FROM_1000_PLUS": {
            return "More than 1000 Employees";
        }
        default: {
            return "Unknown";
        }
    }
}

export const getFullName = (firstName, lastName) => {
    return firstName + " " + lastName;
}