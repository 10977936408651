import React, {Component} from "react";
import {connect} from "react-redux";
import {getMainStats} from "../../actions/stats/stats";

class MainStats extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        if (null === this.props.mainStats) {
            dispatch(getMainStats());
        }
    }

    render () {
        return (
            <div className="bg-gray-50 pt-2 sm:pt-6">
                <div className="mt-10 pb-12 bg-white sm:pb-16">
                    <div className="relative">
                        <div className="absolute inset-0 h-1/2 bg-gray-50" />
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="max-w-4xl mx-auto">
                                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Startups</dt>
                                        <dd className="order-1 text-5xl font-extrabold text-indigo-600">+{this.props?.mainStats?.companies}</dd>
                                    </div>
                                    <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Funding rounds</dt>
                                        <dd className="order-1 text-5xl font-extrabold text-indigo-600">+{this.props?.mainStats?.investmentRounds}</dd>
                                    </div>
                                    <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Investors</dt>
                                        <dd className="order-1 text-5xl font-extrabold text-indigo-600">+{this.props?.mainStats?.institutions}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { mainStats } = state.stats;
    return {
        mainStats
    };
}

export default connect(mapStateToProps)(MainStats);
