import {BaseService} from "../BaseService";

class StatsService extends BaseService {
    getStats() {
        return this.instance.get(this.getApiEndpoint("stats"));
    }
    getExtendedStats() {
        return this.instance.get(this.getApiEndpoint("extendedStats"));
    }
}

export default new StatsService();
