import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React from "react";

export const withLocation = (Component) => {
    return props => <Component {...props} location={useLocation()} />;
};

export const withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
};

export const withSearchParams = (Component) => {
    return props => <Component {...props} searchParams={useSearchParams()} />;
}

export const GlobalContext = React.createContext();
