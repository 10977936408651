import { combineReducers } from "redux";
import auth from "./auth/auth";
import stats from "./stats/stats";
import news from "./news/news";
import search from "./search/search";
export default combineReducers({
    search,
    auth,
    stats,
    news
});
