import {API_CALL_SUCCESS, API_CALL_ERROR} from "../types";

import NewsletterService from "../../services/newsletter/NewsletterService";
import React from "react";

export const subscribe = (email, token) => (dispatch) => {
    return NewsletterService.subscribe(email, token).then(
        (response) => {
            dispatch({
                type: API_CALL_SUCCESS,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: API_CALL_ERROR,
                payload: {
                    error: error.response?.data?.message
                }
            });
            return Promise.resolve();
        }
    );
};

