import React, {Component} from "react";
import {connect} from "react-redux";
import {withNavigation, withSearchParams} from "../../helpers/hoc";
import {createSearchParams} from "react-router-dom";

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeSearchTerm = this.onChangeSearchTerm.bind(this);
        this.state = {
            searchTerm: null
        }
    }

    onChangeSearchTerm(e) {
        this.setState({
            ...this.state,
            searchTerm: e.target.value,
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { searchTerm } = this.state;

        if (searchTerm?.length < 3) {
            return;
        }

        this.setState({ searchTerm : null });

        this.props.navigate({
            pathname: "companies",
            search: createSearchParams({
                searchTerm
            }).toString()
        });
    }

    render() {
        const { searchTerm } = this.state;

        return (
            <div className="relative">
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-50" />
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                        <div className="absolute inset-0">
                            <img
                                className="h-full w-full object-cover"
                                src={"/media/website/main-background.jpg"}
                                alt="People working on laptops"
                            />
                            <div className="absolute inset-0 bg-gradient-to-r to-indigo-700 mix-blend-multiply" />
                        </div>
                        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                <span className="block text-white">The ultimate platform to find all</span>
                                <span className="block text-indigo-500">startups in Africa</span>
                            </h1>
                            <div className="lg:grid lg:grid-cols-3 lg:gap-1">
                                <div></div>
                                <div className="mt-10 sm:mt-12">
                                    <form className="sm:max-w-xl sm:mx-auto lg:mx-0"
                                          onSubmit={this.handleSubmit}
                                          ref={(c) => {
                                              this.form = c;
                                          }}
                                    >
                                        <div className="sm:flex">
                                            <div className="min-w-0 flex-1">
                                                <label htmlFor="search" className="sr-only">
                                                    Search
                                                </label>
                                                <input
                                                    id="search"
                                                    id="name"
                                                    type="text"
                                                    placeholder="Startup name..."
                                                    onChange={this.onChangeSearchTerm}
                                                    className="block w-full px-3 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:ring-indigo-100 focus:border-indigo-100"
                                                />
                                            </div>
                                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                                <button
                                                    disabled={searchTerm && searchTerm?.length < 3}
                                                    type="submit"
                                                    className="block w-full py-3 px-4 rounded-md shadow text-white bg-indigo-500 hover:bg-indigo-600 font-medium focus:outline-none"
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(withNavigation(withSearchParams(SearchBar)));
