import React, {Component} from "react";

export class Alert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { message } = this.props;
        return(
            <div className="rounded-md mt-1">
                { message != null &&
                    <div className="flex">
                        <h3 className="text-sm font-medium text-red-500">{this.props.message}</h3>
                    </div>
                }
            </div>
        );
    }
}

export default Alert;


