import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
const API_VERSION = "v1";

export class BaseService {

    instance = axios.create({
        withCredentials: true,
        baseURL: API_BASE_URL
    })

    getBaseUrl() {
        return API_BASE_URL;
    }

    getApiEndpoint(path) {
        return `/${API_VERSION}/${path}`;
    }
}
