import logo from "../assets/logo.png";
import React, {Component} from "react";
import {connect} from "react-redux";
import {withNavigation} from "../helpers/hoc";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

class PasswordChanged extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="relative min-h-screen bg-slate-100 overflow-hidden">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                        <a href="/">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={logo}
                                alt="Built In Africa"
                            />
                        </a>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="mt-6 relative">
                                Your password has been changed successfully.
                            </div>

                            <div className="mt-6">
                                <button
                                    type="submit"
                                    onClick={() => {this.props.navigate("/login", {});}}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Back to Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(withNavigation(withGoogleReCaptcha(PasswordChanged)));

