import React from "react";
import {RECENT_NEWS_SUCCESS} from "../types";
import NewsService from "../../services/news/NewsService";

export const getRecentNews = () => (dispatch) => {
    return NewsService.getNews().then(
        (response) => {
            dispatch({
                type: RECENT_NEWS_SUCCESS,
                payload: {
                    recentNews: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};
