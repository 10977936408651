import React, {Component} from "react";
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {connect} from "react-redux";
import {withNavigation, withSearchParams} from "../helpers/hoc";
import {searchCompanies} from "../actions/search/search";
import {SearchIcon} from '@heroicons/react/solid'
import {getCompanySize} from "../helpers/formatters";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            searchTerm: null
        }
    }

    async componentDidMount() {
        const [searchParams] = this.props.searchParams;
        const searchTerm = searchParams.get("searchTerm");
        const { dispatch } = this.props;
        dispatch(searchCompanies(searchTerm ?? "all"));
    }

    async handleSubmit(searchTerm) {
        if (searchTerm && searchTerm?.length < 3) {
            return;
        }

        this.setState({
            ...this.state,
            loading: true,
        });
        const { executeRecaptcha } = this.props.googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const token = await executeRecaptcha('search');
        const { dispatch } = this.props;
        dispatch(searchCompanies(searchTerm || this.state.searchTerm, token))
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    }

    render() {
        const countryNames = new Intl.DisplayNames(["en"], {type: "region"});
        return (
            <main>
                <div className="max-w-7xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:pt-24 lg:px-8">
                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <h3 className="text-lg leading-6 font-medium text-indigo-600">Companies</h3>
                        <div className="mt-3 sm:mt-0 sm:ml-4">
                            <label htmlFor="mobile-search-candidate" className="sr-only">
                                Search
                            </label>
                            <label htmlFor="desktop-search-candidate" className="sr-only">
                                Search
                            </label>
                            <div className="flex rounded-md shadow-sm">
                                <div className="relative flex-grow focus-within:z-10">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        type="text"
                                        name="mobile-search-companies"
                                        id="mobile-search-companies"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
                                        placeholder="Search"
                                    />
                                    <input
                                        type="text"
                                        name="desktop-search-companies"
                                        id="desktop-search-companies"
                                        className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                                        placeholder="Search companies"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:px-0 lg:px-0">
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                </th>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Industry
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Size
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Year Founded
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Country
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">View</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                            {this.props.companies.map((company) => (
                                                <tr key={company.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="h-10 w-10 flex-shrink-0">
                                                                <a href={company.website} target="_blank">
                                                                    <img className="h-10 w-10 rounded-full" src={company.logo} alt={company.name} />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{company.name}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{company.industry.name}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{getCompanySize(company?.employeesCount)}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{company.yearFounded}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{countryNames.of(company?.countryCode)}</div>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href={`/company?id=${company.id}`} target="_blank" className="text-indigo-600 hover:text-indigo-900">
                                                            View
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const { companies } = state.search;
    const { user } = state.auth;
    return {
        user,
        companies
    };
}

export default connect(mapStateToProps)(withNavigation(withSearchParams(withGoogleReCaptcha(Profile))));
