import React, {Component} from "react";
import logo from '../assets/logo.png';
import {getAuthorizationEndpoint, signup} from "../actions/auth/auth";
import {connect} from "react-redux";
import {withNavigation, withSearchParams} from "../helpers/hoc";
import Alert from "../components/Alert/Alert";
import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

class Signup extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.handleSocialLogin = this.handleSocialLogin.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.state = {
            username: "",
            password: "",
            confirmedPassword: null,
            error: null,
            loading: false,
            isPasswordConfirmed: false
        };
    }

    componentDidMount() {
        const { user } = this.props;
        if (user) {
            setTimeout(() => { this.props.navigate("/", {}) }, 0)
        }
    }

    onChangeUsername(e) {
        this.setState({
            ...this.state,
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        const { confirmedPassword } = this.state;
        const isPasswordNotConfirmed = confirmedPassword !== e.target.value;
        this.setState({
            ...this.state,
            password: e.target.value,
            error: isPasswordNotConfirmed ? "Passwords must be confirmed" : null,
            isPasswordConfirmed: !isPasswordNotConfirmed
        });
    }

    onChangeConfirmPassword(e) {
        const { password } = this.state;
        const isPasswordNotConfirmed = password !== e.target.value;
        this.setState({
            ...this.state,
            confirmedPassword: e.target.value,
            error: isPasswordNotConfirmed ? "Passwords must be confirmed" : null,
            isPasswordConfirmed: !isPasswordNotConfirmed
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
        });
        const { executeRecaptcha } = this.props.googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const token = await executeRecaptcha('signup');
        const { dispatch } = this.props;
        dispatch(signup(this.state.username, this.state.password, token))
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    }

    handleSocialLogin(provider) {
        window.location = getAuthorizationEndpoint(provider);
    }

    render() {
        return (
            <div className="relative min-h-screen bg-slate-100 overflow-hidden">
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                        <a href="/">
                            <img
                                className="mx-auto h-12 w-auto"
                                src={logo}
                                alt="Workflow"
                            />
                        </a>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div>
                                <p className="text-sm font-medium text-center text-gray-700">Sign up with</p>
                                <div className="mt-6 grid grid-cols-2 gap-3">
                                    <div>
                                        <a
                                            onClick={() => { this.handleSocialLogin("google"); }}
                                            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Sign in with Google</span>
                                            <svg className="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">

                                                <clipPath id="p.0"> <path d="m0 0l20.0 0l0 20.0l-20.0 0l0 -20.0z" clipRule="nonzero"/> </clipPath> <g clipPath="url(#p.0)"> <path fill="currentColor" fillOpacity="0.0" d="m0 0l20.0 0l0 20.0l-20.0 0z" fillRule="evenodd"/> <path fill="currentColor" d="m19.850197 8.270351c0.8574047 4.880001 -1.987587 9.65214 -6.6881847 11.218641c-4.700598 1.5665016 -9.83958 -0.5449295 -12.08104 -4.963685c-2.2414603 -4.4187555 -0.909603 -9.81259 3.1310139 -12.6801605c4.040616 -2.867571 9.571754 -2.3443127 13.002944 1.2301085l-2.8127813 2.7000687l0 0c-2.0935059 -2.1808972 -5.468274 -2.500158 -7.933616 -0.75053835c-2.4653416 1.74962 -3.277961 5.040613 -1.9103565 7.7366734c1.3676047 2.6960592 4.5031037 3.9843292 7.3711267 3.0285425c2.868022 -0.95578575 4.6038647 -3.8674583 4.0807285 -6.844941z" fillRule="evenodd"/> <path fill="currentColor" d="m10.000263 8.268785l9.847767 0l0 3.496233l-9.847767 0z" fillRule="evenodd"/> </g>
                                            </svg>
                                        </a>
                                    </div>

                                    <div>
                                        <a
                                            onClick={() => { this.handleSocialLogin("linkedin"); }}
                                            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                        >
                                            <span className="sr-only">Sign in with LinkedIn</span>
                                            <svg className="w-5 h-5 text-blue-500" aria-hidden="true" fill="currentColor" viewBox="0 0 448 512">
                                                <path
                                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                >
                                                </path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 relative">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">Or</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <form className="space-y-6"
                                      onSubmit={this.handleSubmit}
                                      ref={(c) => {
                                          this.form = c;
                                      }}
                                >
                                    <div>
                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="username"
                                            id="username"
                                            autoComplete="email"
                                            placeholder="Email"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={this.onChangeUsername}
                                            value={this.state.username}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={this.onChangePassword}
                                            value={this.state.password}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                            Confirm password
                                        </label>
                                        <input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={this.onChangeConfirmPassword}
                                        />
                                        <Alert message={this.props.error || this.state.error} />
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            disabled={this.state.loading || !this.state.isPasswordConfirmed}
                                        >
                                            Create your account
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="px-4 py-6 bg-white border-t-2 border-gray-200 sm:px-10">
                                <p className="text-xs leading-5 text-gray-500">
                                    By signing up, you agree to our{' '}
                                    <a href="#" className="font-medium text-gray-900 hover:underline">
                                        Terms
                                    </a>
                                    ,{' '}
                                    <a href="#" className="font-medium text-gray-900 hover:underline">
                                        Data Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="#" className="font-medium text-gray-900 hover:underline">
                                        Cookies Policy
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user, username, error } = state.auth;
    return {
        user,
        username,
        error
    };
}

export default connect(mapStateToProps)(withNavigation(withSearchParams(withGoogleReCaptcha(Signup))));
