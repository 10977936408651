import React, {Component} from "react";
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {connect} from "react-redux";
import {subscribe} from "../../actions/newsletter/newsletter";

class FooterComponent extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.state = {
            email: ""
        };
    }

    onChangeEmail(e) {
        this.setState({
            ...this.state,
            email: e.target.value,
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { executeRecaptcha } = this.props.googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const token = await executeRecaptcha('newsletter');
        const { dispatch } = this.props;
        dispatch(subscribe(this.state.email, token))
            .then(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    };

    render() {
        return (
            <footer className="bg-gray-50" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                                    <ul role="list" className="mt-4 space-y-4">
                                        <li key="about">
                                            <a href="/about" className="text-base text-gray-500 hover:text-gray-900">
                                                About
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                                    <ul role="list" className="mt-4 space-y-4">
                                        <li key="privacy">
                                            <a href="/privacy" className="text-base text-gray-500 hover:text-gray-900">
                                                Privacy
                                            </a>
                                        </li>
                                        <li key="terms">
                                            <a href="/terms" className="text-base text-gray-500 hover:text-gray-900">
                                                Terms
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 xl:mt-0">
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                Subscribe to our newsletter
                            </h3>
                            <p className="mt-4 text-base text-gray-500">
                                The latest news, articles, and resources, sent to your inbox.
                            </p>
                            <form className="mt-4 sm:flex sm:max-w-md"
                                  onSubmit={this.handleSubmit}
                                  ref={(c) => {
                                      this.form = c;
                                  }}>
                                <label htmlFor="emailAddress" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    name="emailAddress"
                                    id="emailAddress"
                                    autoComplete="email"
                                    required
                                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-100 focus:border-indigo-100 focus:placeholder-gray-400"
                                    placeholder="Enter your email"
                                    onChange={this.onChangeEmail}
                                    value={this.state.email}
                                />
                                <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                    <button
                                        type="submit"
                                        disabled={this.state.loading}
                                        className="w-full flex items-center justify-center bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-800 text-gray-100 hover:bg-indigo-900"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
                        <div className="flex space-x-6 md:order-2">
                        </div>
                        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                            &copy; Built In Africa {(new Date()).getFullYear()}
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(withGoogleReCaptcha(FooterComponent));
