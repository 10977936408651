import {BaseService} from "../BaseService";

class SearchService extends BaseService {
    searchCompanies(name) {
        return this.instance.post(this.getApiEndpoint("search/companies"), {
            name
        });
    }
    searchContacts(name) {
        return this.instance.post(this.getApiEndpoint("search/contacts"), {
            name
        });
    }
    searchInstitutions(name) {
        return this.instance.post(this.getApiEndpoint("search/institutions"), {
            name
        });
    }
}

export default new SearchService();
