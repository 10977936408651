import React, {Component} from "react";
import Header from '../components/Header/Header.js';
import SearchBar from '../components/SearchBar/SearchBar.js';
import MainStats from '../components/Stats/MainStats.js';
import Footer from "../components/Footer/Footer";
import {connect} from "react-redux";
import News from "../components/News/News";
import {withNavigation} from "../helpers/hoc";
import ExtendedStats from "../components/Stats/ExtendedStats";

class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bg-white">
                <Header/>
                <main>
                    <SearchBar/>
                    <MainStats/>
                    <News />
                    <ExtendedStats />
                    <div className="bg-white">
                        <div
                            className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                <span className="block">Ready to get started?</span>
                                <span
                                    className="block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">Get in touch or create an account.</span>
                            </h2>
                            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                                <a
                                    onClick={() => { this.props.navigate("/signup", {}); }}
                                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(withNavigation(Index));
