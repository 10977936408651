import {
    LOGOUT,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_ERROR,
    API_CALL_SUCCESS, API_CALL_ERROR
} from "../../actions/types";
const initialState = { user: null, username: null };
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case API_CALL_SUCCESS:
            return {
                ...state,
                error: null
            };
        case API_CALL_ERROR:
            return {
                ...state,
                error: payload.error
            };
        case GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                error: null,
                user: payload.user
            };
        case GET_CURRENT_USER_ERROR:
            return {
                ...state,
                error: payload.error,
                user: null
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
