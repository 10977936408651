import StatsService from "../../services/stats/StatsService";
import React from "react";
import {EXTENDED_STATS_SUCCESS, MAIN_STATS_SUCCESS} from "../types";

export const getMainStats = () => (dispatch) => {
    return StatsService.getStats().then(
        (response) => {
            dispatch({
                type: MAIN_STATS_SUCCESS,
                payload: {
                    mainStats: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};

export const getExtendedStats = () => (dispatch) => {
    return StatsService.getExtendedStats().then(
        (response) => {
            dispatch({
                type: EXTENDED_STATS_SUCCESS,
                payload: {
                    extendedStats: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};

