import {EXTENDED_STATS_SUCCESS, MAIN_STATS_SUCCESS} from "../../actions/types";

const initialState =  { mainStats: null, extendedStats: null };
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case MAIN_STATS_SUCCESS:
            return {
                ...state,
                mainStats: payload.mainStats,
            };
        case EXTENDED_STATS_SUCCESS:
            return {
                ...state,
                extendedStats: payload.extendedStats,
            };
        default:
            return state;
    }
}
