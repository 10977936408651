import SearchService from "../../services/search/SearchService";
import React from "react";
import {SEARCH_COMPANIES_SUCCESS, SEARCH_CONTACTS_SUCCESS, SEARCH_INSTITUTIONS_SUCCESS} from "../types";

export const searchCompanies = (name) => (dispatch) => {
    return SearchService.searchCompanies(name).then(
        (response) => {
            dispatch({
                type: SEARCH_COMPANIES_SUCCESS,
                payload: {
                    companies: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};

export const searchContacts = (name) => (dispatch) => {
    return SearchService.searchContacts(name).then(
        (response) => {
            dispatch({
                type: SEARCH_CONTACTS_SUCCESS,
                payload: {
                    contacts: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};

export const searchInstitutions = (name) => (dispatch) => {
    return SearchService.searchInstitutions(name).then(
        (response) => {
            dispatch({
                type: SEARCH_INSTITUTIONS_SUCCESS,
                payload: {
                    institutions: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.resolve();
        }
    );
};

