import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withLocation, withNavigation} from "../../helpers/hoc";
import { Dialog, Transition } from '@headlessui/react'
import {classNames} from "../../helpers/utilities";
import {
    CurrencyDollarIcon, DocumentAddIcon,
    MenuIcon, OfficeBuildingIcon, UserIcon,
    XIcon,
} from '@heroicons/react/outline';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import logo from '../../assets/logo.png';

const navigation = [
    { name: 'Search Companies', href: 'companies', icon: OfficeBuildingIcon},
    { name: 'Search Contacts', href: 'contacts', icon: UserIcon},
    { name: 'Find Investors', href: 'institutions', icon: CurrencyDollarIcon},
    { name: 'Create Profile', href: 'new-profile', icon: DocumentAddIcon}
]

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            sidebarOpen: false
        }
    }

    componentDidMount() {
        const { user, access } = this.props;
        if (!user && access === "isAuthenticated") {
            setTimeout(() => { this.props.navigate("/login", {}) }, 0)
            return;
        }
        this.setState({
            isReady: true
        })
    }

    isCurrent(href) {
        return this.props.location.pathname?.substring(1) === href;
    }

    setSidebarOpen = (val) => this.setState({ sidebarOpen: !!val });

    render() {
        const { user } = this.props;
        const { isReady } = this.state;
        return (
            <>
                {
                    (!!this.props.user && isReady) && (
                        <div>
                            <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-40 md:hidden" onClose={this.setSidebarOpen}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition-opacity ease-linear duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity ease-linear duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 flex z-40">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300 transform"
                                            enterFrom="-translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transition ease-in-out duration-300 transform"
                                            leaveFrom="translate-x-0"
                                            leaveTo="-translate-x-full"
                                        >
                                            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-in-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in-out duration-300"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                        <button
                                                            type="button"
                                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                            onClick={() => this.setSidebarOpen(false)}
                                                        >
                                                            <span className="sr-only">Close sidebar</span>
                                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </Transition.Child>
                                                <div className="flex-1 h-0 pt-4 pb-6 overflow-y-auto">
                                                    <div className="flex-shrink-0 flex items-center px-4">
                                                        <img
                                                            className="h-8 w-auto"
                                                            src={logo}
                                                            alt="Built In Africa"
                                                        />
                                                    </div>
                                                    <nav className="mt-5 px-2 space-y-1">
                                                        {navigation.map((item) => (
                                                            <a
                                                                key={item.name}
                                                                href={item.href}
                                                                className={classNames(
                                                                    this.isCurrent(item.href) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                                                                )}
                                                            >
                                                                <item.icon
                                                                    className={classNames(
                                                                        this.isCurrent(item.href) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                                        'mr-4 flex-shrink-0 h-6 w-6'
                                                                    )}
                                                                    aria-hidden="true" />
                                                                {item.name}
                                                            </a>
                                                        ))}
                                                    </nav>
                                                </div>
                                                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                                    <a href="#" className="flex-shrink-0 group block">
                                                        <div className="flex items-center">
                                                            <div>
                                                                { user.imageUrl ?
                                                                    <img className="inline-block h-10 w-10 rounded-full" src={user.avatar} alt="" /> :
                                                                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                        </svg>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="ml-3">
                                                                <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{`${user.firstName} ${user.lastName}`}</p>
                                                                <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                                <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
                                    <div className="flex-1 flex flex-col pt-4 pb-6 overflow-y-auto">
                                        <div className="flex items-center flex-shrink-0 px-4">
                                            <img
                                                className="h-8 w-auto"
                                                src={logo}
                                                alt="Built In Africa"
                                            />
                                        </div>
                                        <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        this.isCurrent(item.href) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            this.isCurrent(item.href) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-4 flex-shrink-0 h-6 w-6'
                                                        )}
                                                        aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </nav>
                                    </div>
                                    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                        <a href="/profile" className="flex-shrink-0 w-full group block">
                                            <div className="flex items-center">
                                                <div>
                                                    { user.imageUrl ?
                                                        <img className="inline-block h-9 w-9 rounded-full" src={user.avatar} alt="" /> :
                                                        <span className="inline-block h-9 w-9 rounded-full overflow-hidden bg-gray-100">
                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </span>
                                                    }
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{`${user.firstName} ${user.lastName}`}</p>
                                                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="md:pl-64 flex flex-col flex-1">
                                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
                                    <button
                                        type="button"
                                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        onClick={() => this.setSidebarOpen(true)}
                                    >
                                        <span className="sr-only">Open sidebar</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <main className="flex-1">
                                    {
                                        this.props.children
                                    }
                                </main>
                            </div>
                        </div>
                    )
                }
                {
                    (isReady && !this.props.user) && (
                        <div className="bg-white">
                            <Header/>
                            {
                                this.props.children
                            }
                            <Footer/>
                        </div>
                    )
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(withNavigation(withLocation(Layout)));