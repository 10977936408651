import {BaseService} from "../BaseService";


class NewsService extends BaseService {
    getNews() {
        return this.instance.get(this.getApiEndpoint("news"));
    }
}

export default new NewsService();
