import {
    LOGOUT, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_ERROR, API_CALL_SUCCESS, API_CALL_ERROR
} from "../types";

import AuthService from "../../services/auth/AuthService";
import React from "react";

export const signup = (username, password, token) => (dispatch) => {
    return AuthService.signup(username, password, token).then(
        (response) => {
            dispatch({
                type: API_CALL_SUCCESS,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: API_CALL_ERROR,
                payload: {
                    error: error.response?.data?.message
                }
            });
            return Promise.resolve();
        }
    );
};

export const resetPassword = (username, token) => (dispatch) => {
    return AuthService.resetPassword(username, token).then(
        (response) => {
            dispatch({
                type: API_CALL_SUCCESS,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: API_CALL_ERROR,
                payload: {
                    error: error.response?.data?.message
                }
            });
            return Promise.resolve();
        }
    );
};

export const changePassword = (password, token) => (dispatch) => {
    return AuthService.changePassword(password, token).then(
        (response) => {
            dispatch({
                type: API_CALL_SUCCESS,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: API_CALL_ERROR,
                payload: {
                    error: error.response?.data?.message
                }
            });
            return Promise.resolve();
        }
    );
};

export const getCurrentUser = () => (dispatch) => {
    return AuthService.getCurrentUser().then((response) => {
            dispatch({
                type: GET_CURRENT_USER_SUCCESS,
                payload: {
                    user: response.data
                }
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: GET_CURRENT_USER_ERROR,
                payload: {}
            });
            return Promise.resolve();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT
    });
};

export const getAuthorizationEndpoint = (provider) => {
    return AuthService.getAuthorizationEndpoint(provider);
}

export const getLoginEndpoint = () => {
    return AuthService.getLoginEndpoint();
}
