import {BaseService} from "../BaseService";

class NewsletterService extends BaseService {
    subscribe(email, token) {
        return this.instance.post(this.getApiEndpoint("newsletters/subscribe"), {
            email,
            token,
        });
    }
}

export default new NewsletterService();
