import React, { Component } from "react";
import {connect} from "react-redux";
import {withNavigation, withSearchParams} from "../helpers/hoc";
import DataService from "../services/data/DataService";
import {classNames} from "../helpers/utilities";
import {getCompanySize, getFullName} from "../helpers/formatters";

class Company extends Component {
    static DEFAULT_TAB = "summary";

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            currentTab: Company.DEFAULT_TAB,
            company: null,
            employees: []
        }
        this.isCurrentTab = this.isCurrentTab.bind(this);
        this.setCurrentTab = this.setCurrentTab.bind(this);
    }

    componentDidMount() {
        const [searchParams] = this.props.searchParams;
        const id = searchParams.get("id");
        if (id === null) {
            setTimeout(() => { this.props.navigate("/notfound", {}) }, 0)
            return;
        }
        Promise.all([
            DataService.getCompany(id),
            DataService.getCompanyEmployees(id)
        ]).then((results) => {
            const companyData = results[0];
            const employeesData = results[1];
            this.setState({ ...this.state, company: companyData.data, employees: employeesData.data ?? [], ready: true });
        })
    }

    setCurrentTab(e, currentTab) {
        e.preventDefault();
        this.setState({
            currentTab
        })
    }

    isCurrentTab(tabName) {
        const { currentTab } = this.state;
        return (currentTab ?? Company.DEFAULT_TAB) === tabName;
    }

    render() {
        const countryNames = new Intl.DisplayNames(["en"], {type: "region"});
        const { company, employees, ready, currentTab } = this.state;
        return (
            <>
                { (ready && company) ? (
                    <article className="sm:py-24" style={{ height: "48rem" }}>
                        <div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                                    <div className="flex">
                                        <img
                                            className="h-24 w-24 ring-4 ring-white sm:h-32 sm:w-32"
                                            src={company?.logo}
                                            alt={company?.name}
                                        />
                                    </div>
                                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                            <h1 className="text-2xl font-bold text-indigo-600 truncate">{company?.name}</h1>
                                        </div>
                                        <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4"></div>
                                    </div>
                                </div>
                                <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                                    <h1 className="text-2xl font-bold text-indigo-600 truncate">{company?.name}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 sm:mt-2 2xl:mt-5">
                            <div className="border-b border-gray-200">
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <nav className="-mb-px flex space-x-8">
                                        <a
                                            key="summary"
                                            href="#"
                                            className={ this.isCurrentTab("summary") ? 'border-indigo-500 text-indigo-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm' : 'text-gray-900 whitespace-nowrap py-4 px-1 font-medium text-sm' }
                                            onClick={(e) => this.setCurrentTab(e, "summary")}
                                        >
                                            Summary
                                        </a>
                                        <a
                                            key="people"
                                            href="#"
                                            className={this.isCurrentTab("people") ? 'border-indigo-500 text-indigo-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm' : 'text-gray-900 whitespace-nowrap py-4 px-1 font-medium text-sm' }
                                            onClick={(e) => this.setCurrentTab(e, "people")}
                                        >
                                            People
                                        </a>
                                        <a
                                            key="financials"
                                            href="#"
                                            className={this.isCurrentTab("financials") ? 'border-indigo-500 text-indigo-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm' : 'text-gray-900 whitespace-nowrap py-4 px-1 font-medium text-sm' }
                                            onClick={(e) => this.setCurrentTab(e, "financials")}
                                        >
                                            Financials
                                        </a>
                                        <a
                                            key="timeline"
                                            href="#"
                                            className={this.isCurrentTab("timeline") ? 'border-indigo-500 text-indigo-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm' : 'text-gray-900 whitespace-nowrap py-4 px-1 font-medium text-sm' }
                                            onClick={(e) => this.setCurrentTab(e, "timeline")}
                                        >
                                            Timeline
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 max-w-7xl mx-auto sm:px-6 lg:px-8">
                            { currentTab === "summary" && (
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-12">
                                    <div key="country" className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Country</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{countryNames.of(company?.countryCode)}</dd>
                                    </div>
                                    <div key="yearFounded" className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Year founded</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{company?.yearFounded}</dd>
                                    </div>
                                    <div key="website" className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Website</dt>
                                        <a href={company?.website} target="_blank">
                                            <dd className="mt-1 text-sm text-gray-900">{company?.website}</dd>
                                        </a>
                                    </div>
                                    <div key="employeeCount" className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Company size</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{getCompanySize(company?.employeesCount)}</dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">About</dt>
                                        <dd
                                            className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
                                            dangerouslySetInnerHTML={{__html: company?.description}}
                                        />
                                    </div>
                                </dl>
                            )}

                            { currentTab === "people" && (
                                <div className="mt-8 max-w-7xl mx-auto pb-12">
                                    <div className="mt-1 grid grid-cols-1 gap-8 sm:grid-cols-2">
                                        {employees.map((employee) => (
                                            <div
                                                key={employee.user.id}
                                                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                                <div className="flex-shrink-0">
                                                    {
                                                        employee.avatar ? (
                                                            <img className="h-10 w-10 rounded-full" src={employee.avatar} alt={getFullName(employee.user.firstName, employee.user.lastName)} />
                                                        ) : (
                                                            <svg className="h-10 w-10 text-gray-300 rounded-full" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <a href="#" className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="text-sm font-medium text-gray-900">{getFullName(employee.user.firstName, employee.user.lastName)}</p>
                                                        <p className="text-sm text-gray-500 truncate">{employee.position}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            { currentTab === "financials" && (
                                <div></div>
                            )}
                            { currentTab === "timeline" && (
                                <div></div>
                            )}
                        </div>
                    </article>
                ) : (<div style={{minHeight: "600px"}} />)}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(withNavigation(withSearchParams(Company)));