import {BaseService} from "../BaseService";
import {setWithExpiry} from "../../helpers/localStorage";

class AuthService extends BaseService {
    login(username, password) {
        return this.instance
            .post(this.getApiEndpoint("login"), { username, password })
            .then((response) => {
                if (response.data.access_token) {
                    setWithExpiry("user", JSON.stringify(response.data), response.data.expires_in);
                }
                return response.data;
            });
    }

    signup(username, password, token) {
        return this.instance.post(this.getApiEndpoint("accounts"), {
            username,
            password,
            token,
        });
    }

    resetPassword(username, token) {
        return this.instance.post(this.getApiEndpoint("accounts/resetpassword"), {
            username,
            token,
        });
    }

    changePassword(password, token) {
        return this.instance.post(this.getApiEndpoint("accounts/changepassword"), {
            password,
            token,
        });
    }

    getCurrentUser() {
        return this.instance.get(this.getApiEndpoint("accounts"));
    }

    logout(username, password) {
        return this.instance.post(this.getApiEndpoint("logout"), {
            username,
            password,
        });
    }

    getAuthorizationEndpoint(provider) {
        return `${this.getBaseUrl()}/oauth/login/${provider}`;
    }

    getLoginEndpoint() {
        return `${this.getBaseUrl()}${this.getApiEndpoint("login")}`;
    }
}

export default new AuthService();
