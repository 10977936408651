import {BaseService} from "../BaseService";

class DataService extends BaseService {
    getCompany(id) {
        return this.instance.get(this.getApiEndpoint(`companies/${id}`));
    }

    getCompanyEmployees(id) {
        return this.instance.get(this.getApiEndpoint(`companies/${id}/employees`));
    }
}

export default new DataService();
